import { useState, useEffect } from 'react'

import { Container, Grid, Theme } from '@mui/material'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRouter } from 'next/router'

import LinkRegular from '@/components/atoms/LinkRegular'
import Button from '@/components/atoms/Button'
import LeafsIcon from 'public/images/leafs.svg'
import BackIcon from 'public/images/back.svg'
import routes from '@/config/routes'

import { getLogoByLocale } from './utils'
import ComponentStyled from './styled'

type Props = {
  showBtn?: boolean
  showBack?: boolean
  showBlackLogo?: boolean
  onClickBack?: () => void
}

const Header = ({ showBtn = false, showBack = false, showBlackLogo = true, onClickBack }: Props): JSX.Element => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const router = useRouter()
  const [logo, setLogo] = useState<string>(getLogoByLocale(router.locale, showBlackLogo))

  const handleClickBack = () => {
    onClickBack ? onClickBack() : router.push(routes.home)
  }

  useEffect(() => {
    setLogo(getLogoByLocale(router.locale, showBlackLogo))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  return (
    <ComponentStyled>
      <Container maxWidth='xl' className='header-container'>
        <div className='logo-back-wrapper'>
          <LinkRegular href={routes.home}>
            <div className='logo'>
              <Image src={logo} layout='fill' alt='Schär' objectFit='cover' priority />
            </div>
          </LinkRegular>
        </div>

        <Grid container justifyContent='space-between'>
          {showBack && (
            <div>
              <p className='back' onClick={handleClickBack}>
                <BackIcon className='arrow-icon' />
                {t('Back')}
              </p>
            </div>
          )}
          {showBtn && !isMobile && (
            <Grid item>
              <LinkRegular href={routes.wish}>
                <Button label={t('Plant your wish')} icon={<LeafsIcon />} />
              </LinkRegular>
            </Grid>
          )}
        </Grid>
      </Container>
    </ComponentStyled>
  )
}

export default Header
