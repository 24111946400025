import { ReactElement } from 'react'

import styled, { css } from 'styled-components'

type Props = {
  disabled: boolean
  size: 'small' | 'regular' | 'medium' | 'large'
  variant: 'red' | 'blue' | 'outline-grey'
  icon?: ReactElement
  fitToContainer?: boolean
}

const ComponentStyled = styled.div<Props>`
  display: ${({ fitToContainer }) => (fitToContainer ? 'inherit' : 'inline-block')};

  button {
    border: 0;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    position: relative;
    outline: none;
    transition: 0.5s ease all;
    width: ${({ fitToContainer }) => (fitToContainer ? '100%' : 'inherit')};
    text-align: center;

    .loading-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .loading {
        margin: 0;
      }
    }

    label {
      cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
      font-family: 'Cabin';
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: ${({ theme }) => theme.colors.gray6};
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s ease all;

      span {
        margin-right: ${props => (props.icon ? '10px' : '0')};
      }

      svg path {
        fill: ${({ theme }) => theme.colors.gray6};
        transition: 0.5s ease all;
      }
    }

    ${props =>
      props.size === 'small' &&
      css`
        padding: 12px 28px;
        border-radius: 8px;
      `};

    ${props =>
      props.size === 'regular' &&
      css`
        padding: 14px 45px;
        border-radius: 4px;
      `};

    ${props =>
      props.size === 'medium' &&
      css`
        padding: 16px 32px;
        border-radius: 4px;
      `};

    ${props =>
      props.size === 'large' &&
      css`
        padding: 16px 92px;
        border-radius: 4px;
      `};

    ${props =>
      props.variant === 'red' &&
      !props.disabled &&
      css`
        background-color: ${({ theme }) => theme.colors.red};

        &:hover {
          background: ${({ theme }) => theme.colors.redGradient};
        }
      `};

    ${props =>
      props.variant === 'red' &&
      props.disabled &&
      css`
        border: 2px solid ${({ theme }) => theme.colors.gray6};
      `};

    ${props =>
      props.variant === 'blue' &&
      css`
        background-color: ${({ theme }) => theme.colors.blue};
        border: 2px solid ${({ theme }) => theme.colors.blue};

        &:hover {
          background-color: ${({ theme }) => theme.colors.transparent};

          label {
            color: ${({ theme }) => theme.colors.blue};
          }

          svg path {
            fill: ${({ theme }) => theme.colors.blue};
          }
        }
      `};

    ${props =>
      props.variant === 'outline-grey' &&
      css`
        background-color: ${({ theme }) => theme.colors.transparent};
        border: 2px solid ${({ theme }) => theme.colors.gray3};

        label {
          color: ${({ theme }) => theme.colors.gray3};
        }

        &:hover {
          background-color: ${({ theme }) => theme.colors.gray3};

          label {
            color: ${({ theme }) => theme.colors.gray6};
          }

          svg path {
            fill: ${({ theme }) => theme.colors.gray3};
          }
        }
      `};

    ${props =>
      props.disabled &&
      css`
        background: ${({ theme }) => theme.colors.secondaries.opacity100};
        border-color: ${({ theme }) => theme.colors.secondaries.opacity100};

        &:hover {
          background-color: ${({ theme }) => theme.colors.secondaries.opacity100};

          label {
            color: ${({ theme }) => theme.colors.gray6};
          }

          svg path {
            fill: ${({ theme }) => theme.colors.gray6};
          }
        }
      `};
  }
`

export default ComponentStyled
