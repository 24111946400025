import { useEffect, useRef, useState } from 'react'

import { Container, Grid, Theme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import useMediaQuery from '@mui/material/useMediaQuery'

import LinkRegular from '@/components/atoms/LinkRegular'
import WorldIcon from 'public/images/world.svg'
import { EXTERNAL_URLS, LOCALES_WITH_KEYS } from '@/config/constants'

import ComponentStyled from './styled'

const Footer = (): JSX.Element => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <ComponentStyled>
      <div className='top-footer'>{isMobile ? <MobileFooter /> : <DesktopFooter />}</div>
      <div className='bottom-footer'>
        <Container maxWidth='xl'>
          <Grid container>
            <Grid item xs={12}>
              <FooterCopy />
            </Grid>
          </Grid>
        </Container>
      </div>
    </ComponentStyled>
  )
}

const FooterCopy = () => {
  const { t } = useTranslation()

  return (
    <p className='footer-copy'>
      {t(
        'Dr. Schär AG / SPA, Winkelau 9, 39014 Burgstall (BZ) Italy Mwst Nr. IT00605750215, CCIAA BZ 88727 Cap. Soc. 1.100.000 Euro'
      )}
    </p>
  )
}

const MobileFooter = () => (
  <Container maxWidth='xl'>
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Langs />
        </Grid>
      </Grid>
      <Grid container xs={12} alignItems='flex-start' justifyContent='space-between'>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <PrivacyPolicy />
          </Grid>
          <Grid item xs={12}>
            <CookieSettings />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item>
            <Corporate />
          </Grid>
          <Grid item>
            <FoodService />
          </Grid>
          <Grid item>
            <Institute />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Container>
)

const DesktopFooter = () => (
  <Container maxWidth='xl'>
    <Grid container>
      <Grid item xs={6}>
        <Grid container spacing={4} alignItems='center'>
          <Grid item>
            <Langs />
          </Grid>
          <Grid item>
            <PrivacyPolicy />
          </Grid>
          <Grid item>
            <CookieSettings />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={4} alignItems='center' justifyContent='flex-end'>
          <Grid item>
            <Corporate />
          </Grid>
          <Grid item>
            <FoodService />
          </Grid>
          <Grid item>
            <Institute />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Container>
)

const Langs = () => {
  const { t } = useTranslation()

  const langRef = useRef<HTMLDivElement>(null)
  const [openLangs, setOpenLangs] = useState(false)
  const { asPath, locale } = useRouter()
  const currentLang = LOCALES_WITH_KEYS.find((v: { id: string }) => v.id === locale)
  const currentKeyName = currentLang?.keyName || ''

  const toggleOpenLangs = () => {
    setOpenLangs(prevState => !prevState)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      const target = (event.target || null) as Node

      if (langRef.current && !langRef.current.contains(target) && openLangs) {
        toggleOpenLangs()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openLangs])

  return (
    <div className='langs' ref={langRef}>
      {openLangs && (
        <div className='langs-options'>
          {LOCALES_WITH_KEYS.map((lang: { id: string; keyName: string }) => (
            <LinkRegular key={lang.id} href={asPath} locale={lang.id}>
              <p className={`lang-item ${locale === lang.id && 'active'}`}>{t(lang.keyName)}</p>
            </LinkRegular>
          ))}
        </div>
      )}
      <p className='lang-select' onClick={toggleOpenLangs}>
        <WorldIcon />
        <span>{t(currentKeyName)}</span>
      </p>
    </div>
  )
}

const PrivacyPolicy = () => {
  const { locale } = useRouter()
  const currentLang = LOCALES_WITH_KEYS.find((v: { id: string }) => v.id === locale)
  const currentPrivacyUrl = currentLang?.privacyUrl || ''
  const { t } = useTranslation()

  return (
    <a href={currentPrivacyUrl} target='_blank' className='footer-copy footer-link' rel='noreferrer'>
      {t('Privacy Policy')}
    </a>
  )
}

const CookieSettings = () => {
  const { locale } = useRouter()
  const currentLang = LOCALES_WITH_KEYS.find((v: { id: string }) => v.id === locale)
  const currentPrivacyUrl = currentLang?.privacyUrl || ''
  const { t } = useTranslation()

  return (
    <a href={currentPrivacyUrl} target='_blank' className='footer-copy footer-link' rel='noreferrer'>
      {t('Cookie Settings')}
    </a>
  )
}

const Corporate = () => {
  const { t } = useTranslation()

  return (
    <a className='footer-copy footer-link' href={EXTERNAL_URLS.CORPORATE} target='_blank' rel='noreferrer'>
      {t('Dr. Schär Corporate')}
    </a>
  )
}

const FoodService = () => {
  const { t } = useTranslation()

  return (
    <a className='footer-copy footer-link' href={EXTERNAL_URLS.FOODSERVICE} target='_blank' rel='noreferrer'>
      {t('Dr. Schär Foodservice')}
    </a>
  )
}

const Institute = () => {
  const { t } = useTranslation()

  return (
    <a className='footer-copy footer-link' href={EXTERNAL_URLS.INSTITUTE} target='_blank' rel='noreferrer'>
      {t('Dr. Schär Institute')}
    </a>
  )
}

export default Footer
