import React, { ReactElement } from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'

import routes from '@/config/routes'

import { ComponentStyled } from './styled'

type Props = {
  children: ReactElement
  href: string
  linkColor?: string
  locale?: string
}

const LinkRegular = ({ children, href = routes.home, linkColor, locale }: Props): JSX.Element => {
  const { locale: currentLocale } = useRouter()
  const formatLocal = locale || currentLocale

  return (
    <ComponentStyled linkColor={linkColor}>
      <Link href={href} locale={formatLocal}>
        <a>{children}</a>
      </Link>
    </ComponentStyled>
  )
}

export default LinkRegular
