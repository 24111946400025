import styled from 'styled-components'

const ComponentStyled = styled.div`
  position: relative;

  .footer-copy {
    font-family: 'Cabin';
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.secondaries.opacity10};

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      margin-bottom: 34px;
    }
  }

  .footer-link {
    &:hover {
      text-decoration: underline;
    }
  }

  .langs {
    position: relative;
    z-index: 6;

    .langs-options {
      position: absolute;
      bottom: 43px;
      width: 230px;
      max-height: 320px;
      overflow-y: scroll;
      background: ${({ theme }) => theme.colors.secondaries.opacity10};

      .lang-item {
        padding: 16px;
        transition: 0.5s ease all;

        &:hover,
        &.active {
          background: ${({ theme }) => theme.colors.secondaries.opacity30};
        }
      }
    }

    .lang-select {
      cursor: pointer;
      text-decoration: underline;
      font-family: 'Cabin';
      font-weight: 700;
      font-size: 13px;
      line-height: 13px;
      color: #fdfcfb;
      display: flex;
      align-items: center;

      span {
        margin-left: 12px;
      }

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        margin-bottom: 27px;
      }
    }
  }

  .top-footer {
    padding: 20px 0;
    background-color: ${({ theme }) => theme.colors.red};
  }

  .bottom-footer {
    padding: 10px 0;
    background-color: ${({ theme }) => theme.colors.red2};
  }
`

export default ComponentStyled
