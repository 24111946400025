import styled from 'styled-components'

const ComponentStyled = styled.div`
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;

  .header-container {
    position: relative;

    .logo-back-wrapper {
      position: absolute;
      top: -20px;
      right: 0;
      display: flex;
      width: 180px;
      left: 15px;

      .logo {
        position: relative;
        width: 180px;
        height: 130px;
        left: 0;
      }

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        justify-content: center;
        align-items: center;
        top: -48px;
        left: calc(50% + 20px);
        transform: translateX(-50%);
      }
    }

    .back {
      display: flex;
      align-items: center;
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.black};
      left: 15px;
      top: 130px;
      position: absolute;
      cursor: pointer;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        top: 22px;
        left: 15px;
      }

      .arrow-icon {
        margin-right: 10px;
      }
    }
  }
`

export default ComponentStyled
