const routes = {
  home: '/',
  wish: '/wish',
  wishes: '/wishes',
  authError: '/auth-error',
  policy: '/privacy-policy',
  biodiversity: '/biodiversity',
  community: '/community',
  cookies: '/cookies',
}

export default routes
