import { ReactElement } from 'react'

import ComponentStyled from './styled'

type Props = {
  label: string
  onClick?: () => void
  icon?: ReactElement
  isFetching?: boolean
  disabled?: boolean
  size?: 'small' | 'regular' | 'medium' | 'large'
  variant?: 'red' | 'blue' | 'outline-grey'
  fitToContainer?: boolean
}

const Button = ({
  label,
  onClick,
  icon,
  isFetching = false,
  disabled = false,
  size = 'regular',
  variant = 'red',
  fitToContainer = false,
}: Props): JSX.Element => (
  <ComponentStyled
    fitToContainer={fitToContainer}
    disabled={disabled || isFetching}
    size={size}
    icon={icon}
    variant={variant}
  >
    <button onClick={onClick} disabled={disabled || isFetching}>
      <label>
        <span>{label}</span>
        {icon && icon}
      </label>
      {isFetching && (
        <div className='loading-wrapper'>
          <p className='loading'>Loading...</p>
        </div>
      )}
    </button>
  </ComponentStyled>
)

export default Button
