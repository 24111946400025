import styled from 'styled-components'

type Props = {
  linkColor?: string
}

export const ComponentStyled = styled.span<Props>`
  width: auto;

  a {
    width: 100%;
    height: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({ linkColor, theme }) => linkColor || theme.colors.black};
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`
